import React from 'react';

function Blog() {
  return (
    <div>
      <h1>
        It's ya blog boi
      </h1>
      <h3>
        Entry 1
      </h3>
      <p>
        This is where I tell you about my story; I'll sprinkle a few juicy details in here that pique your curiosity about my personal brand. Minutes later, when you become hungry and ill from lack of contact with me, you will decide it's time to head back to the 'Home' page where you can contact me.
      </p>
      <hr />
      <h3>
        Entry 2
      </h3>
      <p>
        Surprise: it's Entry 2. Didn't think you'd be reading this so soon, did ya, partner? Well I've got news for you and that news is that you want me bad. Why don't we cut to the chase and get in direct contact with each other? Feel free to start that process via the 'Home' page contact form! Talk soon.
      </p>
    </div>
  )
}

export default Blog;
